const LOGIN = {
  EMAIL_NOT_VERIFIED: 'Email not verified',
  EMAIL_VERIFIED: 'EMAIL_VERIFIED',
  INVALID_CREDENTIALS: 'Invalid Username or Password.',
  MULTIPLE_SESSIONS: 'multipleSessions',
  NOT_AUTHENTICATED: 'Error, Individual Not Authenticated',
  PASSWORD_RESET_REQUIRED: 'PASSWORD_RESET_REQUIRED'
}

export { LOGIN }
