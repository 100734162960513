import { createI18n } from 'vue-i18n'

function loadLocaleMessages() {
  const localeModules = import.meta.glob('@/locales/*.json', { eager: true })
  const messages = {}

  Object.keys(localeModules).forEach((moduleKey) => {
    let locale = null
    // lookbehind is not compatible with Safari regex, so let's parse the string for the locale instead.
    try {
      locale = moduleKey.split('/')[3].split('.')[0]
    } catch (err) {
      console.log(err)
    }

    if (locale) {
      messages[locale] = {}
      Object.keys(localeModules[moduleKey]['default']).forEach((key) => {
        messages[locale][key] = localeModules[moduleKey]['default'][key]
      })
    }
  })

  return messages
}

export default createI18n({
  legacy: false,
  locale: import.meta.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
