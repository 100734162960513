import stores from '@/stores'
import * as Sentry from '@sentry/vue'

export default () => {
  const sentryErrorWithContext = (error) => {
    const orgData = stores.useOrganizationStore().getOrgData
    const merchantData = stores.useOrganizationStore().getMerchantData
    const profileData = stores.useUserStore().getProfileData
    const sessionData = stores.useSessionStore()
    const errorMsg = error?.data?.message || error?.message

    if (errorMsg) {
      try {
        Sentry.withScope(() => {
          Sentry.setContext('requestData', {
            orgData: orgData || null,
            merchantData: merchantData || null,
            url: error.request?.responseURL,
            method: error.request?.method,
            status: error.response?.status,
            headers: error.request?.headers,
            sessionData: sessionData || null
          })
          Sentry.setUser(profileData || null)
          Sentry.captureException(errorMsg)
        })
      } catch (error) {
        console.error('Could not log to Sentry:', error)
      }
    }
  }

  return {
    sentryErrorWithContext
  }
}
