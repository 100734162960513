export default () => {
  const TRANSACTION_STATUS = {
    APPROVED: 'approved',
    CANCELED: 'canceled',
    CHECK_SUBMITTED: 'check_submitted',
    CHECK_SUBMITTED_ALT: 'check submitted',
    CLOSED: 'closed',
    PROCESSING: 'processing', // RF specific
    REFUNDED: 'refunded',
    SUCCEEDED: 'succeeded', // RF specific
    VOIDED: 'voided'
  }

  /**
   * calculate the processing fees for a transaction. An extra up charge is added is the user pays fees and transaction type is credit card
   * @param {object} processFeesInformation
   * @returns {string}
   */

  const calculateProcessingFees = (processFeesInformation) => {
    // TODO - get the Rainforest fee structures from the database per merchant or from environment variables
    const rainforestTransactionFee = 0.5
    const rainforestCreditDebitRate = 0.03
    const rainforestAmexRate = 0.035
    const rainforestACH = 0.01

    const {
      givingSubTotal,
      merchantData,
      userPaysFees,
      selectedPaymentMethod,
      transactionType = ''
    } = processFeesInformation
    const hasNeededMerchantData = checkRequiredMerchantData(merchantData, transactionType)
    if (!hasNeededMerchantData) {
      return '0.00'
    }
    const { achRate, achTransactionFee, ccRate, ccTransactionFee } = merchantData

    let rate = 0,
      fee = 0
    if (merchantData.paymentProviderId === 1) {
      // GPI CC and ACH
      rate = transactionType === 'CREDITCARD' ? Number(ccRate) : Number(achRate)
      fee = transactionType === 'CREDITCARD' ? Number(ccTransactionFee) : Number(achTransactionFee)
    } else {
      // Rainforest CC and ACH
      rate = Number(rainforestACH)
      fee = Number(rainforestTransactionFee) // same per transaction fee for cc and ach
      if (transactionType === 'CREDITCARD') {
        rate =
          selectedPaymentMethod.cardProvider === 'AMERICAN_EXPRESS'
            ? Number(rainforestAmexRate)
            : Number(rainforestCreditDebitRate)
      }
    }

    const feeTotal = Number(Number(givingSubTotal) * rate + fee).toFixed(2)

    if ((userPaysFees && transactionType === 'CREDITCARD') || (userPaysFees && merchantData.paymentProviderId === 2)) {
      return calculateFeeWithUpcharge(givingSubTotal, rate, fee)
    }
    return feeTotal
  }

  /**
   * Calculates the total processing fee with an upcharge for a donor who decides to cover the processing fees.
   *
   * This function takes the gift amount, the fee rate, and a fixed fee, and calculates the total fee,
   * including the upcharge. The upcharge is applied to ensure that the donor's gift covers both the
   * original amount and the associated processing fees.
   * @param {number} giftAmount - The amount of the donation or gift.
   * @param {number} feeRate - The processing fee rate as a decimal (e.g., 0.03 for a 3% fee).
   * @param {number} fixedFee - A fixed processing fee that is added on top of the fee rate (e.g., .50).
   * @returns {number} - The total fee required to cover both the gift amount and the processing fees.
   */
  const calculateFeeWithUpcharge = (giftAmount, feeRate, fixedFee) => {
    const totalFee = (giftAmount + fixedFee) / (1 - feeRate) - giftAmount
    return feeRound(totalFee)
  }

  // Helper function to round to 2 decimal places
  function feeRound(num) {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  /**
   * Check if needed merchant data keys have values
   * @param {object} merchantData
   * @param {string} transactionType
   * @returns {boolean}
   */
  const checkRequiredMerchantData = (merchantData, transactionType) => {
    return transactionType === 'CREDITCARD'
      ? !!(merchantData.ccRate && merchantData.ccTransactionFee)
      : !!(merchantData.achRate && merchantData.achTransactionFee)
  }

  /**
   * @description Get the payment type based on the manual pay_type and online giving payment_type
   * @param {*} pay_type
   * @param {*} payment_type
   * @returns {string}
   */
  const getPayType = (pay_type, payment_type) => {
    switch (pay_type) {
      case 'S':
        return 'Cash'
      case 'C':
        return 'Check'
      case 'R':
        return 'Credit/Debit'
      case 'D':
        return 'Direct deposit'
      case 'L':
        return payment_type || 'Online'
      case 'A':
        return 'ACH'
      case 'O':
        return 'Other'
    }
  }

  /**
   * determine the state of the transaction
   * @param {string} paymentSystemType
   * @param {string} status
   * @param {string} return_id
   * @returns {number} 0: refunded or voided already,
   * 1: refundable (closed),
   * 2: voidable (approved),
   * 3: ach refund started already but not closed yet,
   * 4: ach refunded already and closed,
   * 5: ach check submitted but not closed yet,
   * -1: unknown
   */
  const getTransactionState = (paymentSystemType, status, return_id) => {
    if (!status) return -1
    status = status.toLowerCase()
    if (paymentSystemType === 'ACH') {
      if ((status.includes(TRANSACTION_STATUS.APPROVED) || status === TRANSACTION_STATUS.SUCCEEDED) && !return_id) {
        // Rainforest Succeeded
        return 8 // approved, ach transaction processed
      } else if (
        [TRANSACTION_STATUS.CHECK_SUBMITTED, TRANSACTION_STATUS.CHECK_SUBMITTED_ALT].includes(status) &&
        !!return_id
      ) {
        return 3 // ach refund started already but not closed yet
      } else if (
        [
          TRANSACTION_STATUS.CHECK_SUBMITTED,
          TRANSACTION_STATUS.CHECK_SUBMITTED_ALT,
          TRANSACTION_STATUS.PROCESSING
        ].includes(status) &&
        !return_id
      ) {
        return 5 // ach check submitted but not closed yet
      } else if (
        [TRANSACTION_STATUS.APPROVED, TRANSACTION_STATUS.REFUNDED, TRANSACTION_STATUS.SUCCEEDED].includes(status) &&
        !!return_id
      ) {
        return 4 // ach refunded already and closed
      } else {
        return -1 // unknown
      }
    } else {
      // CREDIT CARD

      if ((status.includes(TRANSACTION_STATUS.APPROVED) || status === TRANSACTION_STATUS.PROCESSING) && !return_id) {
        // Rainforest = Processing
        return 2 // voidable (approved)
      } else if ([TRANSACTION_STATUS.APPROVED, TRANSACTION_STATUS.PROCESSING].includes(status) && !!return_id) {
        return 6 // cc refund started but not closed
      } else if ([TRANSACTION_STATUS.CLOSED, TRANSACTION_STATUS.SUCCEEDED].includes(status) && !return_id) {
        // Rainforest = Succeeded
        return 1 // refundable (closed)
      } else if (
        [TRANSACTION_STATUS.CLOSED, TRANSACTION_STATUS.REFUNDED, TRANSACTION_STATUS.SUCCEEDED].includes(status) &&
        !!return_id
      ) {
        return 0 // refunded
      } else if ([TRANSACTION_STATUS.VOIDED, TRANSACTION_STATUS.CANCELED].includes(status)) {
        return 7 // voided
      } else {
        return -1 // unknown
      }
    }
  }

  /**
   * Sort the transaction rows by batch_dt, then rec_id.
   * rec_id is auto incrementing key in the cscondtl table in database to tell which transactions are the most recent.
   * @param {*} dateA Ag Grid cell value to compare. Unused arg
   * @param {*} dateB Ag Grid cell value to compare. Unused arg
   * @param {object} nodeA Ag Grid corresponding RowNodes
   * @param {object} nodeB Ag Grid corresponding RowNodes
   * @returns {number}
   */
  const sortTransactionRowsByRecId = (dateA, dateB, nodeA, nodeB) => {
    if (nodeA?.data?.cscondtl?.[0]?.batch_dt >= nodeB?.data?.cscondtl?.[0]?.batch_dt) {
      return nodeA?.data?.cscondtl?.[0]?.rec_id > nodeB?.data?.cscondtl?.[0]?.rec_id ? 1 : -1
    } else return -1
  }

  return {
    calculateProcessingFees,
    getPayType,
    getTransactionState,
    sortTransactionRowsByRecId,
    TRANSACTION_STATUS,
    feeRound,
    calculateFeeWithUpcharge
  }
}
