import TransactionsProxy from '@/proxy/TransactionsProxy'
import stores from '@/stores'
import sentryErrorLoggingHelper from '@/helpers/sentryErrorLoggingHelper'
import useRruleHelpers from '@/helpers/rrule'

const { newRecurrencePattern, startDate } = useRruleHelpers()
const defaultRecurrencePattern = newRecurrencePattern()

export default {
  /**
   * get all recurring transactions for giver
   *
   * @returns {Promise<void>}
   */
  async getMemberRecurringTransactionsData() {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()
    let response = null
    const payload = {
      giverId: stores.useUserStore().getGiverRecordData.giverId
    }
    try {
      response = await proxy.getMemberRecurringTransactions(payload)

      if (response) {
        this.recurringGifts = response.data
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return result
  },

  /**
   * get all recurring transactions for account
   * @param accountId
   * @returns {Promise<{success: boolean, error: null}>}
   */
  async getScheduledGiftCountById(accountId) {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()
    const webId = stores.useOrganizationStore().getWebId
    let response = null

    try {
      response = await proxy.getScheduledGiftsByAccountIdCount(webId, accountId)

      if (response && response.data?.success) {
        result.scheduledGiftCount = response.data.data
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return result
  },
  /**
   * create a recurring gift
   * @param gift
   * @returns {Promise<void>}
   */
  async createGift(gift) {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()
    let response = null
    try {
      response = await proxy.createRecurringGift(gift)

      if (response) {
        result.success = true
        await this.getMemberRecurringTransactionsData()
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return result
  },

  /**
   * update recurring gift
   * @param gift
   * @returns {Promise<void>}
   */
  async updateGift(gift) {
    const result = {
      success: false,
      error: null
    }
    const proxy = new TransactionsProxy()
    let response = null
    try {
      response = await proxy.updateRecurringGift(gift)

      if (response) {
        result.success = true
        await this.getMemberRecurringTransactionsData()
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return result
  },

  resetSelectedScheduledGift() {
    this.selectedScheduledGift = {
      active: true,
      created: null,
      deleted: false,
      end: null,
      giftscheduleitems: [],
      giver: {},
      giverId: null,
      id: null,
      isUserPayingFees: false,
      lastSuccess: null,
      recurrencePattern: defaultRecurrencePattern,
      start: startDate(defaultRecurrencePattern).toDate(),
      token: null,
      tokenId: null,
      updated: null
    }
  }
}
