/* ============
 * Getters for the session module
 * ============
 */

export default {
  isAuthenticated(state) {
    return state.authenticated
  },

  getUserEmail() {
    return localStorage.getItem('username')
  },

  getSmsToken(state) {
    return state.smsToken
  },

  getSmsPhone(state) {
    return state.smsPhone
  },

  getToken(state) {
    return state.token
  },

  getMemberId(state) {
    return state.memberId
  },

  isInitialized(state) {
    return state.initialized
  }
}
