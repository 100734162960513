import Proxy from './Proxy'

export default class OrgProxy extends Proxy {
  /**
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${import.meta.env.VITE_ACCOUNT_API_BASE_URL}/v1`, parameters)
  }

  /**
   * Fetch webId
   * @param customerId
   * @returns {Promise}
   */
  async getWebId({ customerId }) {
    this.showConsoleLogInDevMode('[OrgProxy] getWebId() customerId: ', customerId)
    return this.submit('get', `${this.endpoint}/org/webid/${customerId}`)
  }

  /**
   * Fetch public config
   * @param webId
   * @returns {Promise}
   */
  async getOrgName({ webId }) {
    this.showConsoleLogInDevMode('[OrgProxy] getOrgName() webId: ', webId)
    return await this.submit('get', `${this.endpoint}/org/name/${webId}`)
  }

  /**
   * Fetch Authenticated Org Data
   * @param webId
   * @returns {Promise}
   */
  async getOrgData({ webId }) {
    this.showConsoleLogInDevMode('[OrgProxy] getOrgData() webId: ', webId)
    return await this.submit('get', `${this.endpoint}/org/data/${webId}`)
  }

  /**
   * Fetch public config
   * @param customerId
   * @returns {Promise}
   */
  async getOrganizationDataByCustomerId(customerId) {
    this.showConsoleLogInDevMode('[OrgProxy] getOrganizationDataByCustomerId() customerId: ', customerId)
    return await this.submit('get', `${this.endpoint}/org/${customerId}`)
  }

  /**
   * * Update Org Data
   *
   * @param webId
   * @param payload
   * @returns {Promise}
   */
  async updateOrganizationData(webId, payload) {
    return this.submit('patch', `${this.endpoint}/org/${webId}`, payload)
  }
}
