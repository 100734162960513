import UserProxy from '@/proxy/UserProxy'
import sentryErrorLoggingHelper from '@/helpers/sentryErrorLoggingHelper'

export default {
  async getStaffListData() {
    let response = {}
    const proxy = new UserProxy()
    try {
      response = await proxy.getStaffList()
      if (response?.data) {
        response.success = true
        this.staffListData = response.data
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return response
  },
  async createStaffProfile(payload) {
    const proxy = new UserProxy()
    try {
      const response = await proxy.postStaffProfile(payload)
      if (response?.data) {
        return { ...response, success: true }
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      return { success: false, error }
    }
  },

  async checkStaffProfile(email, webId) {
    const proxy = new UserProxy()
    try {
      const response = await proxy.checkStaffProfile(email, webId)
      if (response?.data.success) {
        return response
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
  },

  async updateStaffProfile(payload) {
    let response = {}
    const proxy = new UserProxy()
    try {
      response = await proxy.patchStaffProfile(payload)
      if (response?.data) {
        response.success = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return response
  },

  async deleteStaffProfile(payload) {
    let response = {}
    const proxy = new UserProxy()
    try {
      response = await proxy.deleteStaffProfile(payload)
      if (response?.data) {
        response.success = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return response
  }
}
