export default {
  getAccounts(state) {
    return state.accounts
  },
  getActiveAccounts(state) {
    return state.activeAccounts
  },
  getDefaultAccount(state) {
    return state.activeAccounts.find((account) => account.udf1 === true)
  },
  getScheduledGiftAccounts(state) {
    return state.scheduledGiftAccounts
  }
}
