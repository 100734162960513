import GivingProxy from '@/proxy/GivingProxy'
import OrgProxy from '@/proxy/OrgProxy'
import sentryErrorLoggingHelper from '@/helpers/sentryErrorLoggingHelper'

/**
 * organization service async actions
 */

export default {
  /**
   * fetch org name
   * @param webId
   * @returns {Promise<{success: boolean}>}
   */
  async fetchOrgName({ webId }) {
    const result = {
      success: false
    }
    const proxy = new OrgProxy()
    let response = null
    if (!this.orgName || !this.orgData) {
      try {
        response = await proxy.getOrgName({ webId: webId })

        if (response?.status && response.status === 200 && response.data.toString() === 'Web Id invalid or not found') {
          result.success = false
          return result
        }

        if (response?.status && response.status === 200) {
          result.success = true
          this.orgData = response.data
          this.orgName = response.data.orgname
        }
      } catch (error) {
        sentryErrorLoggingHelper().sentryErrorWithContext(error)
      }
    }
    return result
  },
  /**
   * fetch org data after auth
   *  @param webId
   *  @returns {Promise<{success: boolean}>}
   */
  async fetchOrgData({ webId }) {
    const result = {
      success: false
    }
    const proxy = new OrgProxy()
    let response = null
    try {
      response = await proxy.getOrgData({ webId: webId })

      if (response?.status && response.status === 200) {
        result.success = true
        this.orgData = response.data
        this.orgName = response.data.orgname
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return result
  },

  /**
   * fetch org webId
   * @param customerId
   * @returns {Promise<{success: boolean}>}
   */
  async fetchWebId({ customerId = null }) {
    const result = {
      success: false
    }
    const proxy = new OrgProxy()
    let response = null
    try {
      response = await proxy.getWebId({ customerId: customerId })

      if (response?.status && response.status === 200) {
        result.success = true
        this.webId = response.data.webid
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return result
  },
  /**
   * Update org
   * @param payload
   * @returns {Promise<{success: boolean}>}
   */
  async updateOrganizationData(payload) {
    const response = {}
    const proxy = new OrgProxy()
    try {
      const response = await proxy.updateOrganizationData(this.webId, payload)

      if (response?.data && response?.status === 200) {
        this.orgData = response.data
        response.success = true
        return response
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return response
  },

  /**
   * fetch merchant data
   * @param webId
   * @returns {Promise}
   */
  async fetchMerchantData(webId) {
    const result = {
      success: false
    }
    const proxy = new GivingProxy()
    let response = null
    try {
      response = await proxy.getMerchantData(webId)

      if (response?.status && response.status === 200) {
        result.success = true
        this.merchantData = response.data
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return result
  },

  /**
   * Update merchant data
   * @param payload
   * @returns {Promise<{}|{data}|*>}
   */
  async updateMerchantData(payload) {
    const response = {}
    const proxy = new GivingProxy()
    try {
      const response = await proxy.updateMerchantData(payload)
      if (response?.data) {
        this.merchantData = response.data
        response.success = true
        return response
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
    }
    return response
  },

  /**
   *
   * @param webId
   * @returns {Promise<boolean>}
   */
  async getIsMerchantVerified(webId) {
    if (!this.merchantData || (this.merchantData.paymentProviderId === 2 && !this.merchantData.verified)) {
      await this.fetchMerchantData(webId)
    }

    switch (this.merchantData.paymentProviderId) {
      case 1:
        return true
      case 2:
        return this.merchantData.verified
      default:
        return true
    }
  }
}
