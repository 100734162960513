import axios from 'axios'
import SessionProxy from '@/proxy/SessionProxy'
import UserProxy from '@/proxy/UserProxy'
import stores from '@/stores'
import * as Sentry from '@sentry/vue'
import GivingProxy from '@/proxy/GivingProxy'
import { LOGIN } from '@/constants/apiConstants.js'

export default {
  /**
   * Session startup
   * @returns Boolean
   */
  start() {
    // Initial start up
    this.initialized = true
  },

  /*
   * load user security
   * @returns Boolean
   */
  async loadUserSecurity() {
    // TODO this causes issues when token expires
    const userProxy = new UserProxy()
    const userSecurity = await userProxy.getUserSecurity()
    this.userSecurity = userSecurity && userSecurity.data ? userSecurity.data : null
    return true
  },

  /**
   * Set Token (into axios Bearer token)
   * @param token
   * @returns {Promise<{success: boolean}>}
   */
  async setToken(token) {
    localStorage.setItem('sko_token', token)
    this.token = token
    return { success: true }
  },

  /**
   * Remove Token (from axios Bearer token)
   * @returns {Promise<{success: boolean}>}
   */
  async removeToken() {
    localStorage.removeItem('sko_token')
    delete axios.defaults.headers.common['Authorization']
    return { success: true }
  },

  /**
   * Create a session via login
   *
   * @param payload
   * @returns {Promise<{success: boolean}>}
   */
  async staffLogin(payload) {
    const result = {
      success: false
    }

    // prevent users from logging in again in another tab
    if (this.authenticated) {
      result.error = LOGIN.MULTIPLE_SESSIONS
      return result
    }

    const proxy = new SessionProxy()
    let response = null
    try {
      response = await proxy.staffLogin(payload)
      if (response && response.data.token && response.data.user_name) {
        result.success = true
        await this.setToken(response.data.token)
        const userStore = stores.useUserStore()
        const organizationStore = stores.useOrganizationStore()
        userStore.profileData = {
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          portalemail: response.data.user_email,
          username: response.data.user_name,
          user_id: response.data.user_id,
          rec_id: response.data.rec_id,
          active: true
        }

        const webId = organizationStore.getWebId
        await organizationStore.fetchOrgData({ webId: webId })
        await organizationStore.fetchMerchantData(webId)
        if (!organizationStore.getMerchantData) {
          Sentry.withScope(() => {
            Sentry.setContext('requestData', {
              orgData: stores.useOrganizationStore().getOrgData,
              merchantData: stores.useOrganizationStore().getMerchantData
            })
            Sentry.setUser(stores.useUserStore().profileData)
            Sentry.captureException(Error('MISSING MERCHANT DATA'))
          })
        }
        this.authenticated = true

        // start the session timer, logs out after 30 mins of inactivity
        stores.useLogoutTimerStore().initialize()
      } else {
        if (response.data.message === 'UserAccessDenied') {
          result.success = false
          result.error = 'UserAccessDenied'
          return result
        }
        result.error = LOGIN.INVALID_CREDENTIALS

        // any error clears previous auth state
        localStorage.removeItem('userSecurity')
        this.authenticated = false
      }
    } catch (error) {
      result.error = error.data.message || LOGIN.INVALID_CREDENTIALS
      // any error clears previous auth state
      localStorage.removeItem('userSecurity')
      this.authenticated = false
    }
    return result
  },

  /**
   * Create a member session via login
   *
   *  @param payload
   *  @returns {Promise<{success: boolean}>}
   */
  async memberLogin(payload) {
    const result = {
      success: false
    }

    // prevent users from logging in again in another tab
    if (this.authenticated) {
      result.error = LOGIN.MULTIPLE_SESSIONS
      return result
    }

    const proxy = new SessionProxy()
    const userStore = stores.useUserStore()
    const organizationStore = stores.useOrganizationStore()

    let response = null
    try {
      response = await proxy.memberLogin(payload)
      // if (response && response.data.token && response.data.user_name) {
      if (response?.data?.token && response?.data?.profile) {
        result.success = true
        await this.setToken(response.data.token) // "session" token

        // userStore.profileData is user for both staff logins and member logins
        userStore.profileData = response.data.profile
        this.memberId = response.data.profile.ind_id // used in routing to indicate a NON-staff member user
        // get the merchant data now that a valid user has authenticated
        // if it fails we cannot go any further.
        const webId = organizationStore.getWebId

        await organizationStore.fetchOrgData({ webId: webId })
        await organizationStore.fetchMerchantData(webId)

        if (!organizationStore.getMerchantData) {
          Sentry.withScope(() => {
            Sentry.setContext('requestData', {
              orgData: stores.useOrganizationStore().getOrgData,
              merchantData: stores.useOrganizationStore().getMerchantData
            })
            Sentry.setUser(stores.useUserStore().profileData)
            Sentry.captureException(Error('MISSING MERCHANT DATA'))
          })
        }

        // get the profile associated giverData with payment related info
        const payload = {
          ind_id: userStore.profileData.ind_id,
          merchantId: organizationStore.getMerchantData.merchantId
        }

        await userStore.fetchProfileGiverRecord(payload)

        if (!userStore.getGiverRecordData) {
          Sentry.withScope(() => {
            Sentry.setContext('requestData', {
              orgData: stores.useOrganizationStore().getOrgData,
              merchantData: stores.useOrganizationStore().getMerchantData
            })
            Sentry.setUser(stores.useUserStore().profileData)
            Sentry.captureException(Error('MISSING GIVER DATA'))
          })
        }

        this.authenticated = true
        // TODO POST MVP
        // Load user security
        // await this.loadUserSecurity()
        // start the session timer, logs out after 30 mins of inactivity
        stores.useLogoutTimerStore().initialize()
      } else {
        if (response.response.data.message) {
          result.error = response.response.data.message
        } else {
          result.error = LOGIN.INVALID_CREDENTIALS
        }
        // any error clears previous auth state
        localStorage.setItem('userSecurity', null)
        this.authenticated = false
      }
    } catch (error) {
      result.error = error.data?.message || LOGIN.INVALID_CREDENTIALS
      // any error clears previous auth state
      localStorage.setItem('userSecurity', null)
      this.authenticated = false
    }
    return result
  },

  /**
   * Create a member session via login when the user is using SMS
   *
   *  @param payload
   *  @returns {Promise<{success: boolean}>}
   */
  async smsMemberLogin(payload) {
    const result = {
      success: false
    }

    // prevent users from logging in again in another tab
    if (this.authenticated) {
      result.error = LOGIN.MULTIPLE_SESSIONS
      return result
    }

    const proxy = new SessionProxy()
    const userStore = stores.useUserStore()
    const organizationStore = stores.useOrganizationStore()

    let response = null
    try {
      response = await proxy.smsMemberLogin(payload)
      if (response?.data?.token && response?.data?.profile) {
        result.success = true
        await this.setToken(response.data.token) // "session" token

        // userStore.profileData is user for both staff logins and member logins
        const tempEmail = userStore.profileData.portalemail
        userStore.profileData = response.data.profile
        this.memberId = response.data.profile.ind_id

        if (!response.data.profile.portalemail) {
          userStore.profileData.portalemail = tempEmail
        }

        this.memberId = response.data.profile.ind_id // used in routing to indicate a NON-staff member user

        // get the profile associated giverData with payment related info
        const payload = {
          ind_id: userStore.profileData.ind_id,
          merchantId: organizationStore.getMerchantData.merchantId
        }

        await userStore.fetchProfileGiverRecord(payload, true)

        if (!userStore.getGiverRecordData) {
          Sentry.withScope(() => {
            Sentry.setContext('requestData', {
              orgData: stores.useOrganizationStore().getOrgData,
              merchantData: stores.useOrganizationStore().getMerchantData
            })
            Sentry.setUser(stores.useUserStore().profileData)
            Sentry.captureException(Error('MISSING GIVER DATA'))
          })
        }

        this.authenticated = true
        stores.useLogoutTimerStore().initialize()
      } else {
        result.error = LOGIN.INVALID_CREDENTIALS
        this.authenticated = false
      }
    } catch (error) {
      result.error = LOGIN.INVALID_CREDENTIALS
      this.authenticated = false
    }
    return result
  },

  /**
   * End a session by logout
   * @returns {Promise<{success: boolean}>}
   */
  async logout() {
    const result = {
      success: true
    }

    stores.useLogoutTimerStore().killTimer()

    // LETS CLEAR THE STORES!
    Object.keys(stores).forEach((store) => {
      stores[store]().$reset()
    })
    await this.removeToken()
    return result
  },

  /**
   * forgot Password
   *  @param payload
   *  @returns {Promise<{success: boolean}>}
   *
   *   */
  async forgotPassword(payload) {
    const result = {
      success: false
    }
    const proxy = new SessionProxy()
    let response = null
    try {
      response = await proxy.forgotPassword(payload)
      if (response?.success) {
        result.success = true
        // TODO forgot-password - what is the result?
      }
    } catch (error) {
      result.success = false
      result.error = error
    }
    return result
  },

  /**
   * forgot Individual Password
   *  @param payload
   *  @returns {Promise<{success: boolean}>}
   *
   */
  async forgotMemberPassword(payload) {
    const result = {
      success: false
    }
    const proxy = new SessionProxy()
    let response = null
    try {
      response = await proxy.forgotMemberPassword(payload)
      if (response?.success) {
        result.success = true
        // TODO forgot-password - what is the result?
      }
    } catch (error) {
      result.success = false
      result.error = error
    }
    return result
  },

  /**
   * @param payload
   * @returns {Promise<{success: boolean}>}
   */
  async sendPasswordResetEmail(payload) {
    const proxy = new UserProxy()
    let response = {}
    try {
      response = await proxy.sendPasswordResetEmail(payload)
    } catch (error) {
      response.success = false
      response.error = error
    }
    return response
  },

  /**
   * @param email
   * @param token
   * @returns {Promise<{success: boolean}>}
   */
  async verifyEmail(email, token) {
    const proxy = new GivingProxy()
    const webId = stores.useOrganizationStore().getWebId
    let response = {}

    try {
      response = await proxy.verifyEmail(email, token, webId)
    } catch (error) {
      response.success = false
      response.error = error
    }
    return response
  },

  /**
   * @param token
   * @returns {Promise<{success: boolean}>}
   */
  async isTokenValid(token) {
    const proxy = new UserProxy()
    let response = {}
    try {
      response = await proxy.validateToken(token)
    } catch (error) {
      response.success = false
      response.error = error
    }
    return response
  },
  /**
   * @param payload
   * @returns {Promise<{success: boolean}>}
   */
  async updatePasswordClearResetToken(payload) {
    const proxy = new UserProxy()
    let response = {}
    try {
      response = await proxy.updatePasswordClearResetToken(payload)
    } catch (error) {
      response.success = false
      response.error = error
    }
    return response
  },

  async verifySmsSession(payload) {
    const proxy = new GivingProxy()
    let response = {}
    try {
      response = await proxy.verifySmsSession(payload)
    } catch (error) {
      response.success = false
      response.error = error
    }

    return response
  },

  async resendGiverEmailVerification(email) {
    const proxy = new GivingProxy()
    let response = {}

    const webId = stores.useOrganizationStore().getWebId

    try {
      response = await proxy.resendGiverEmailVerification(webId, email)
    } catch (error) {
      response.success = false
      response.error = error
    }

    return response
  },

  async initGuestGiver(payload, webId) {
    const proxy = new GivingProxy()
    const response = await proxy.initGuestGiver(payload, webId)

    if (response.data.success) {
      const userStore = stores.useUserStore()
      const { giverId, merchantId, token, skIndId } = response.data.guestGiver

      userStore.giverRecordData = {
        giverId,
        merchantId,
        skIndId
      }
      await this.setToken(token)

      return {
        success: !!this.token,
        guestGiverRecord: response.data.guestGiver
      }
    }

    return response
  }
}
