export default () => ({
  authenticated: false,
  memberId: null,
  initialized: false,
  token: '', // maybe unused
  localToken: null, // because localStorage isn't reactive
  queryState: {},
  requestQueue: [],
  errors: {},
  smsToken: null,
  smsPhone: null
})
