/* ============
 * Getters for the session module
 * ============
 */

export default {
  getTimeoutInMS(state) {
    return state.timeoutInMS
  },

  getTimeoutId(state) {
    return state.timeoutId
  }
}
