import Proxy from './Proxy'

export default class UserProxy extends Proxy {
  /**
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${import.meta.env.VITE_GIVING_API_BASE_URL}/v1`, parameters)
  }

  async checkStaffProfile(email, webId) {
    return this.submit('get', `${this.endpoint}/profile/staff/checkprofile/${webId}/${email}`)
  }

  /**
   * Get all staff users
   * @returns {Promise}
   */
  async getStaffList() {
    return this.submit('get', `${this.endpoint}/profile/staff/`)
  }

  /**
   * Get single staff user
   * @param rec_id
   * @returns {Promise}
   */
  async getStaffProfile({ rec_id }) {
    return this.submit('get', `${this.endpoint}/profile/staff/${rec_id}`)
  }

  /**
   * Validate password token
   * @param {*} token
   * @returns
   */
  async validateToken(token) {
    return this.submit('get', `${this.endpoint}/profile/password/token/${token}`)
  }

  async deleteStaffProfile(id) {
    return this.submit('delete', `${this.endpoint}/profile/staff/${id}`)
  }

  async patchStaffProfile(payload) {
    return this.submit('patch', `${this.endpoint}/profile/staff/updateprofile/${payload.user_id}`, payload)
  }

  /**
   * Update a giver profile using the
   * endpoint for this is part of giving API, not the CHMS-API
   * @param payload
   * @returns {Promise}
   */
  async updateMemberProfile(payload) {
    return this.submit('patch', `${this.endpoint}/profile/${payload.ind_id}`, payload)
  }

  /**
   * Update a mobile giving giver profile using the
   * @param payload
   * @returns {Promise}
   */
  async updateMobileGivingMemberProfile(payload) {
    const { ind_id, webId } = payload
    return this.submit('patch', `${this.endpoint}/profile/mobilegiving/${webId}/${ind_id}`, payload)
  }

  /**
   * Create a new giver profile using the
   * endpoint for this is part of giving API, not the CHMS-API
   */
  async createMemberProfile(payload) {
    return this.submit('post', `${this.endpoint}/profile/${payload.webid}/profile`, payload)
  }

  /**
   * Create a new mobile giver profile using the
   * endpoint for this is part of giving API, not the CHMS-API
   */
  async createMobileGivingMemberProfile(payload) {
    return this.submit('post', `${this.endpoint}/profile/${payload.webid}/mobilegiving/profile`, payload)
  }

  async postStaffProfile(payload) {
    return this.submit('post', `${this.endpoint}/profile/staff/createprofile`, payload)
  }

  // password reset functions
  async sendPasswordResetEmail(payload) {
    return this.submit('post', `${this.endpoint}/profile/password/reset`, payload)
  }

  /**
   * Updates the giver settings records
   * endpoint for this is part of giving API, not the CHMS-API
   * @param payload
   * @returns {Promise}
   */
  async updateGiverRecord(payload) {
    return this.submit('post', `${this.endpoint}/givers/settings`, payload)
  }

  // both member and staff as indicated in the payload
  async updatePasswordClearResetToken(payload) {
    return this.submit('post', `${this.endpoint}/profile/password/save`, payload)
  }
}
