import GivingProxy from '@/proxy/GivingProxy'
import stores from '@/stores'
import GiversProxy from '@/proxy/GiversProxy.js'

export default {
  /**
   * Get all givers for the org
   * @returns {Promise<{success: boolean}>}
   */
  async getGiversByWebId(year) {
    const proxy = new GivingProxy()
    const webId = stores.useOrganizationStore().getWebId

    const response = await proxy.getGiversByWebId(webId, year)

    if (response?.status === 200) {
      this.givers = response.data
      return { success: true }
    }

    return { success: false }
  },

  /**
   * Send email to givers with statement info
   * @param payload
   * @returns {Promise<{success: boolean}>}
   */
  async sendStatementInvite(payload) {
    const proxy = new GiversProxy()
    const webId = stores.useOrganizationStore().getWebId

    const response = await proxy.sendStatementInvite(webId, payload)

    if (response?.status === 200) {
      return response.data
    }

    return { success: false }
  },

  /**
   * Get giver record
   * @param isMobileGiving
   * @param giverId
   * @param individualId
   * @returns {Promise<void>}
   */
  async getGiverRecord(isMobileGiving, giverId) {
    if (!isMobileGiving) {
      const payload = {
        ind_id: stores.useUserStore().getGiverRecordData.ind_id,
        merchantId: stores.useOrganizationStore().getMerchantData.merchantId
      }
      await stores.useUserStore().fetchProfileGiverRecord(payload)
    } else {
      const payload = {
        giverId: giverId,
        merchantId: stores.useOrganizationStore().getMerchantData.merchantId
      }

      await stores.useUserStore().fetchProfileGiverRecord(payload, true)
    }
  }
}
