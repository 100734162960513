import dayjs from 'dayjs'
import { toRaw } from 'vue'
import IsBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
import utc from 'dayjs/plugin/utc'
import reportConstants from '@/constants/report.constants.js'

dayjs.extend(isoWeek)
dayjs.extend(IsBetween)
dayjs.extend(utc)

export default {
  currencyFormatter(value) {
    return `$${Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
  },
  /**
   * Set the original transaction data
   * @param rowData
   */
  setOriginalRowData(rowData) {
    this.originalTransactionData = toRaw(rowData)
  },

  /**
   * Get the report label for the Gifts report
   * @param selectedReportPeriod
   * @return {string}
   */
  giftsReportLabel(selectedReportPeriod) {
    const { dateStart, dateEnd } = this.getDateRangeByPeriod(selectedReportPeriod)
    return `${dateStart.format('MMM D, YYYY')} - ${dateEnd.format('MMM D, YYYY')}`
  },

  /**
   * Filter transactions by date
   * @param selectedReportPeriod
   * @param reportPaymentType
   * @param reportType
   */
  filterTransactionsByDate(selectedReportPeriod, reportPaymentType, reportType) {
    const { dateStart, dateEnd } = this.getDateRangeByPeriod(selectedReportPeriod)

    this.filteredTransactionData = this.originalTransactionData.filter((transaction) => {
      const transactionDate = dayjs.utc(transaction.batch_dt)

      if (!transactionDate.isBetween(dateStart, dateEnd, 'day', '[]')) {
        return false
      }

      if (transaction.csgivingtransaction?.return_id) {
        return false
      }

      const paymentTypes = {
        credit: 'R',
        online: 'L',
        ach: 'C',
        cash: 'S',
        directDeposit: 'D',
        other: 'O'
      }

      if (
        reportPaymentType !== reportConstants.giftPaymentTypes.ALL &&
        transaction.pay_type !== paymentTypes[reportPaymentType]
      ) {
        return false
      }

      return this.shouldIncludeTransaction(reportType, transaction)
    })
  },

  shouldIncludeTransaction(frequencyType, transaction) {
    const isRecurring = transaction.csgivingtransaction?.is_recurring

    switch (frequencyType) {
      case reportConstants.giftFrequencyTypes.ALL:
        return true
      case reportConstants.giftFrequencyTypes.ONETIME:
        return !isRecurring
      case reportConstants.giftFrequencyTypes.SCHEDULED:
        return isRecurring
      default:
        return false
    }
  },

  /**
   * Get the date range by the selected report period
   * @param reportPeriod
   * @return {*|{dateStart: dayjs.Dayjs, dateEnd: dayjs.Dayjs}}
   */
  getDateRangeByPeriod(reportPeriod) {
    const dateRanges = {
      [reportConstants.giftRangeSelections.LAST_MONTH]: {
        dateStart: dayjs().subtract(1, 'month').startOf('month'),
        dateEnd: dayjs().subtract(1, 'month').endOf('month')
      },
      [reportConstants.giftRangeSelections.CURRENT_YEAR]: {
        dateStart: dayjs().startOf('year'),
        dateEnd: dayjs().endOf('year')
      },
      [reportConstants.giftRangeSelections.LAST_YEAR]: {
        dateStart: dayjs().subtract(1, 'year').startOf('year'),
        dateEnd: dayjs().subtract(1, 'year').endOf('year')
      },
      default: {
        dateStart: dayjs().startOf('month'),
        dateEnd: dayjs().endOf('month')
      }
    }

    return dateRanges[reportPeriod] || dateRanges.default
  },

  /**
   * Aggregate data by report type
   * @param data
   * @param selectedReportPeriod
   * @param $t
   * @return {{aggregatedTotals: {totalAmount, period: string}[], type: string}|{aggregatedTotals: {totalAmount, period: *}[], type: string}}
   */
  aggregateDataByReportType(data, selectedReportPeriod, $t) {
    if (
      [reportConstants.giftRangeSelections.CURRENT_MONTH, reportConstants.giftRangeSelections.LAST_MONTH].includes(
        selectedReportPeriod
      )
    ) {
      return this.aggregateByWeek(data, $t)
    }
    return this.aggregateByMonth(data, $t)
  },

  /**
   * Aggregate data by week
   * @param data
   * @param $t
   * @return {{aggregatedTotals: {totalAmount, period: string}[], type: string}}
   */
  aggregateByWeek(data, $t) {
    const amountsByWeek = data.reduce((acc, item) => {
      // Parse the date in UTC
      const batchDate = dayjs.utc(item.batch_dt)
      const dayOfMonth = batchDate.date()
      const weekKey = Math.floor((dayOfMonth - 1) / 7) + 1

      acc[weekKey] = acc[weekKey] || 0
      item.cscondtl.forEach((detail) => {
        acc[weekKey] += parseFloat(detail.amt)
      })

      return acc
    }, {})

    const totalWeeksInMonth = 5
    const aggregatedTotals = Array.from({ length: totalWeeksInMonth }, (_, i) => ({
      period: `${$t('reports.reportLabels.weekPeriod')} ${i + 1}`,
      totalAmount: amountsByWeek[i + 1] || 0
    }))

    return { aggregatedTotals, type: 'week' }
  },

  /**
   * Aggregate data by month
   * @param data
   * @param $t
   * @return {{aggregatedTotals: {totalAmount, period: *}[], type: string}}
   */
  aggregateByMonth(data, $t) {
    const months = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december'
    ]

    const amountsByMonth = data.reduce((acc, item) => {
      const batchDate = dayjs.utc(item.batch_dt)
      const monthKey = batchDate.month() // 0 (Jan) to 11 (Dec)

      acc[monthKey] = acc[monthKey] || 0
      item.cscondtl.forEach((detail) => {
        acc[monthKey] += parseFloat(detail.amt)
      })

      return acc
    }, {})

    const aggregatedTotals = months.map((month, index) => ({
      period: $t(`reports.reportLabels.months.${month}`),
      totalAmount: amountsByMonth[index] || 0
    }))

    return { aggregatedTotals, type: 'month' }
  },

  /**
   * Create the line chart options for the Gifts report
   * @param aggregatedTotals
   * @param reportType
   * @param $t
   * @return {{data, series: [{xKey: string, marker: {enabled: boolean}, type: string, stroke: string, yKey: string}], axes: [{position: string, type: string, title: {text: *}},{position: string, type: string, title: {text}}]}}
   */
  createGiftLineChartOptions(aggregatedTotals, reportType, $t) {
    const xAxisTitle =
      reportType === 'week' ? $t('reports.reportLabels.weekOfMonth') : $t('reports.reportLabels.monthOfYear')

    return {
      data: aggregatedTotals,
      title: {
        text: `${$t('reports.reportLabels.giftsDuringSelectedPeriod')}`,
        fontSize: 18
      },
      series: [
        {
          type: 'line',
          xKey: 'period',
          yKey: 'totalAmount',
          marker: { enabled: true },
          stroke: 'blue'
        }
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          title: { text: xAxisTitle }
        },
        {
          type: 'number',
          position: 'left',
          title: { text: $t('reports.reportLabels.totalAmount') }
        }
      ]
    }
  },

  /**
   * Create the donut chart options for the Gifts report
   * We could access filteredTransactionData from the store, but we want to use a computed property, so this gives us the reactivity we need
   * @param filteredTransactionData
   * @param $t
   * @return {{data: [{category, value: *},{category, value: *}], series: [{calloutLabelKey: string, innerLabels: [{spacing: number, fontSize: number, text: string},{spacing: number, fontSize: number, text: string},{spacing: number, fontSize: number, text, fontWeight: string},{color: string, fontSize: number, text: string, fontWeight: string}], innerCircle: {fill: string}, tooltip: {renderer: (function(*): {content: string}), enabled: boolean}, innerRadiusRatio: number, type: string, angleKey: string}], legend: {enabled: boolean}, title: {text}}}
   */
  createGiftDonutChartOptions(filteredTransactionData, $t) {
    const totalAmount = filteredTransactionData.reduce((sum, transaction) => sum + (transaction?.amt || 0), 0)
    const totalFees = filteredTransactionData.reduce(
      (sum, transaction) =>
        sum + (transaction.csgivingtransaction?.user_paid_fee ? transaction?.csgivingtransaction?.fee || 0 : 0),
      0
    )

    return {
      data: [
        { category: $t('reports.reportLabels.totalAmount'), value: totalAmount },
        { category: $t('reports.reportLabels.totalFees'), value: totalFees }
      ],
      series: [
        {
          type: 'donut',
          calloutLabelKey: 'category',
          angleKey: 'value',
          innerRadiusRatio: 0.8,
          innerLabels: [
            {
              text: `${$t('reports.reportLabels.fees')} ${this.currencyFormatter(totalFees)}`,
              fontSize: 11,
              spacing: 4
            },
            { text: this.currencyFormatter(totalAmount), fontWeight: 'bold', fontSize: 14, color: 'green' }
          ],
          innerCircle: { fill: '#f4f4f4' },
          tooltip: {
            enabled: true,
            renderer: (params) => ({ content: this.currencyFormatter(params.datum[params.angleKey]) })
          }
        }
      ],
      legend: { enabled: true, position: 'right' }
    }
  },

  /**
   * Create the pie chart options for the Gift Feess report
   * We could access filteredTransactionData from the store, but we want to use a computed property, so this gives us the reactivity we need
   * @param filteredTransactionData
   * @param $t
   * @return {{data: [{category, value: *},{category, value: *}], series: [{calloutLabelKey: string, innerLabels: [{spacing: number, fontSize: number, text: string},{spacing: number, fontSize: number, text: string},{spacing: number, fontSize: number, text, fontWeight: string},{color: string, fontSize: number, text: string, fontWeight: string}], innerCircle: {fill: string}, tooltip: {renderer: (function(*): {content: string}), enabled: boolean}, innerRadiusRatio: number, type: string, angleKey: string}], legend: {enabled: boolean}, title: {text}}}
   */
  createGiftFeesPieChartOptions(filteredTransactionData, $t) {
    const totalFees = filteredTransactionData.reduce(
      (sum, transaction) => sum + (transaction?.csgivingtransaction?.fee || 0),
      0
    )

    const feesByUserPaidFee = filteredTransactionData.reduce(
      (acc, transaction) => {
        const fee = transaction?.csgivingtransaction?.fee || 0
        const userPaidFee = transaction?.csgivingtransaction?.user_paid_fee

        if (userPaidFee) {
          acc.userPaid += fee
        } else {
          acc.userNotPaid += fee
        }

        return acc
      },
      { userPaid: 0, userNotPaid: 0 }
    )

    feesByUserPaidFee.userPaid = Number(feesByUserPaidFee.userPaid.toFixed(2))
    feesByUserPaidFee.userNotPaid = Number(feesByUserPaidFee.userNotPaid.toFixed(2))

    // Prepare the data array for the chart
    const data = [
      {
        category: $t('reports.reportLabels.userPaidFee'),
        totalFees: feesByUserPaidFee.userPaid
      },
      {
        category: $t('reports.reportLabels.userDidNotPayFee'),
        totalFees: feesByUserPaidFee.userNotPaid
      }
    ]

    return {
      data,
      series: [
        {
          type: 'pie',
          angleKey: 'totalFees',
          calloutLabelKey: 'category',
          sectorLabelKey: 'totalFees',
          sectorLabel: {
            formatter: (params) => this.currencyFormatter(params.datum.totalFees)
          },
          sectorSpacing: 4,
          calloutLabelName: 'Fees'
        }
      ],
      legend: { enabled: true, position: 'right' },
      title: {
        text: `${$t('reports.reportLabels.totalFeesChartTitle')}: ${this.currencyFormatter(totalFees)}`,
        fontSize: 18
      }
    }
  }
}
