import { ampli } from '@/ampli'

try {
  ampli.load({
    environment: process.env.NODE_ENV,
    client: {
      apiKey: import.meta.env.VITE_AMPLITUDE_API_KEY
    }
  })
} catch (error) {
  console.log('Error loading Amplitude', error)
}

export default () => ampli
