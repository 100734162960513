/* eslint-disable no-multi-assign */
/* eslint-disable no-bitwise */
import permissions from './permissions'

const accessSlots = {
  1: 'access1',
  2: 'access2',
  3: 'access3',
  4: 'access4',
  5: 'access5',
  6: 'access6',
  7: 'access7',
  8: 'access8',
  9: 'access9',
  10: 'access10'
}

export default {
  /**
   * @param {*} user
   * @returns array of access permissions from the user profile data
   */
  getUserAccessPermissions(user) {
    return {
      access1: user.access1,
      access2: user.access2,
      access3: user.access3,
      access4: user.access4,
      access5: user.access5,
      access6: user.access6,
      access7: user.access7,
      access8: user.access8,
      access9: user.access9,
      access10: user.access10
    }
  },

  /**
   *
   * @param {*} data
   * @returns array of access permissions from token user data
   */
  getTokenUserAccessPermissions(data) {
    return {
      access1: data.user.access_1,
      access2: data.user.access_2,
      access3: data.user.access_3,
      access4: data.user.access_4,
      access5: data.user.access_5,
      access6: data.user.access_6,
      access7: data.user.access_7,
      access8: data.user.access_8,
      access9: data.user.access_9,
      access10: data.user.access_10
    }
  },

  /**
   *
   * @param {*} data
   * @returns array of access permissions from the login results data from csuser
   */
  getAccessPermissions(data) {
    return {
      access1: data.access_1,
      access2: data.access_2,
      access3: data.access_3,
      access4: data.access_4,
      access5: data.access_5,
      access6: data.access_6,
      access7: data.access_7,
      access8: data.access_8,
      access9: data.access_9,
      access10: data.access_10
    }
  },

  setAccessPermission(userPermissions, permissionId, checked) {
    if (!(permissionId in permissions)) {
      // permission does not exist, assume they do not have intended permission, may be mistake
      return
    }
    const { slot, mask } = permissions[permissionId]
    const slotName = accessSlots[slot]
    if (!slotName) {
      // invalid slot
      return
    }
    // handle potential string numbers
    let userAccess = +userPermissions[slotName]
    if (!Number.isInteger(userAccess)) {
      // junk data
      return
    }
    if (checked) {
      userAccess = userAccess |= mask
    } else {
      userAccess = userAccess &= ~mask
    }
    return userAccess.toString()
  },

  /**
   *
   * @param {*} userPermissions array of permissions from csuser access values 1-10
   * @param {*} permissionId permission id from permissions object e.g. SEC_CONTRIBUTION_ACCESS
   * @returns boolean if the permission is found in the userPermissions array and the user has access
   */
  hasPermission(userPermissions, permissionId) {
    if (!(permissionId in permissions)) {
      // permission does not exist, assume they do not have intended permission, may be mistake
      return false
    }
    const { slot, mask } = permissions[permissionId]
    const slotName = accessSlots[slot]
    if (!slotName || !(slotName in userPermissions)) {
      // old tokens may not have permissions, they need a new token
      return false
    }
    // handle potential string numbers
    const userAccess = +userPermissions[slotName]
    if (!Number.isInteger(userAccess)) {
      // junk data
      return false
    }
    // eslint-disable-next-line no-bitwise
    return (userAccess & mask) !== 0
  }
}
