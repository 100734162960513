/* ============
 * Getters for the staff module
 * ============
 */

import userSecurity from '@/helpers/userSecurity'
import { SEC_ACCESS } from '@/helpers/permissions'

export default {
  // entire staff list
  getStaffList: (state) => state.staffListData,

  // single staff profile
  getStaffProfileData: (state) => {
    let data = state.staffProfileData
    const userPermissions = userSecurity.getUserAccessPermissions(data)
    data.online_giving_access = userSecurity.hasPermission(userPermissions, SEC_ACCESS)
    return data
  }
}
