const paymentConstants = {
  CS_GIVING_TRANSACTION_PAYMENT_TYPES: {
    CREDIT: 'Credit',
    CREDIT_DEBIT: 'Credit/Debit',
    CHECK: 'Check'
  },
  PAYMENT_TYPES: {
    CREDITCARD: 'CREDITCARD',
    ACH: 'ACH'
  },
  SESSION_TYPES: {
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    PAYMENT_METHOD_CONFIG: 'PAYMENT_METHOD_CONFIG'
  },
  GPI_ERROR_MESSAGES: {
    AVS_CVV:
      'The card information entered had a AVS or CVV verification error. Please verify the information is correct and try again.',
    GENERIC: 'Cannot add payment method at this time'
  },
  GPI_REFERENCE_ID_PREFIX: 'MER'
}

export default paymentConstants
