import AccountsProxy from '@/proxy/AccountsProxy'
import sentryErrorLoggingHelper from '@/helpers/sentryErrorLoggingHelper'
import stores from '@/stores'

export default {
  /**
   * get all accounts
   *
   * @returns {Promise<void>}
   */

  async getAccountsData(isMobile = false) {
    const result = {
      success: false,
      error: null
    }
    const proxy = new AccountsProxy()

    let response = null
    try {
      // TODO: These are essentially the same, and once mobile giving is launched we can consolidate
      if (isMobile) {
        // doesnt require auth
        const webId = stores.useOrganizationStore().getWebId
        response = await proxy.getMobileGivingAccounts(webId)
      } else {
        // requires auth
        response = await proxy.getAccounts()
      }

      if (response) {
        this.accounts = this.sortAccountsByAcctName(response.data)
        this.activeAccounts = response.data.filter((account) => {
          return account.active_ind
        })

        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return result
  },

  /**
   * get scheduled gift accounts
   *
   * @returns {Promise<void>}
   */
  async getScheduledGiftAccountsData() {
    const result = {
      success: false,
      error: null
    }
    const proxy = new AccountsProxy()
    let response = null
    try {
      const webId = stores.useOrganizationStore().getWebId
      response = await proxy.getScheduledGiftAccounts(webId)

      if (response) {
        this.scheduledGiftAccounts = this.sortAccountsByAcctName(response.data)
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }
    return result
  },

  /**
   * create a new account
   *
   *  @param payload
   * @returns {Promise<void>}
   */
  async createNewAccount(payload) {
    const result = {
      success: false,
      error: null
    }
    const proxy = new AccountsProxy()

    let response = null
    try {
      response = await proxy.createAccount(payload)

      if (response) {
        await this.getAccountsData()

        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return result
  },

  /**
   * update existing account
   *
   *  @param payload
   *  @param newScheduledGiftsAccount
   * @returns {Promise<void>}
   */
  async updateExistingAccount(payload, newScheduledGiftsAccount = null) {
    const result = {
      success: false,
      error: null
    }

    const account = {
      acct_cd: payload.acct_cd || '',
      acct_id: payload.acct_id,
      acct_name: payload.acct_name,
      linkto: payload.linkto || '',
      linkfrom: payload.linkfrom || '',
      acct_grp_1: payload.acct_grp_1 || '',
      acct_grp_2: payload.acct_grp_2 || '',
      acct_type: this.getAccountType(payload.acct_type),
      tax_ind: payload.tax_ind,
      active_ind: payload.active_ind,
      payment_ind: payload.payment_ind || false,
      pledge_ind: payload.pledge_ind || false,
      pledge_amt: payload.pledge_amt,
      udf1: payload.udf1 || false
    }

    const proxy = new AccountsProxy()

    let response = null
    try {
      response = await proxy.updateAccount(account, newScheduledGiftsAccount)

      if (response) {
        await this.getAccountsData()
        result.success = true
      } else {
        result.error = true
      }
    } catch (error) {
      sentryErrorLoggingHelper().sentryErrorWithContext(error)
      result.error = error
    }

    return result
  },

  getAccountType(typeString) {
    switch (typeString) {
      case 'Group':
        return 'C'
      case 'Regular':
        return 'D'
      default:
        return 'D'
    }
  },
  sortAccountsByAcctName(arr) {
    return arr.sort((a, b) => {
      const nameA = a.acct_name.toUpperCase()
      const nameB = b.acct_name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
  }
}
