import Proxy from './Proxy'

export default class GiversProxy extends Proxy {
  /**
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${import.meta.env.VITE_GIVING_API_BASE_URL}/v1/givers`, parameters)
  }

  /**
   * * Send emails with statement info to givers
   * @param webId
   * @param payload
   * @returns {Promise<unknown>}
   */
  async sendStatementInvite(webId, payload) {
    return this.submit('post', `${this.endpoint}/statement/invite/${webId}`, payload)
  }
}
