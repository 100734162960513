const givingFlowConstants = {
  GIVER_ACCOUNTS_CTA_ACTIONS: {
    SIGN_UP: 'signUp',
    SIGN_IN: 'signIn'
  },
  GIVING_CARD_OPTIONS: {
    GIVE_AS_GUEST: 'guestGift',
    SCHEDULED_GIFT: 'scheduledGift'
  },
  FREQUENCY_CHOICES: {
    ONCE: 'ONCE',
    RECURRING: 'RECURRING'
  }
}

export default givingFlowConstants
