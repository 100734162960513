import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useRruleHelpers from '@/helpers/rrule'

dayjs.extend(utc)

export default () => {
  const { newRecurrencePattern, startDate } = useRruleHelpers()
  const defaultRecurrencePattern = newRecurrencePattern()

  return {
    recurringGifts: [],
    selectedScheduledGift: {
      active: true,
      created: null,
      deleted: false,
      end: null,
      giftscheduleitems: [],
      giver: {},
      giverId: null,
      id: null,
      isUserPayingFees: false,
      lastSuccess: null,
      recurrencePattern: defaultRecurrencePattern,
      start: startDate(defaultRecurrencePattern).toDate(),
      token: null,
      tokenId: null,
      updated: null
    }
  }
}
