import useRruleHelpers from '@/helpers/rrule'

const { newRecurrencePattern, startDate } = useRruleHelpers()
export default {
  async setPaymentInfo(data) {
    this.checkOutData.paymentInfo = data
  },

  resetCheckOutData() {
    const updatedRecurrencePattern = newRecurrencePattern()
    this.checkOutData.accounts = {}
    this.checkOutData.amount = ''
    this.checkOutData.feeAmount = 0
    this.checkOutData.giftNote = ''
    this.checkOutData.paymentInfo = {}
    this.checkOutData.saveAsDefaultPaymentInfo = false
    this.checkOutData.transactionResult = {}
    this.checkOutData.userPaysFees = false
    this.checkOutRecurringGift.active = false
    this.checkOutRecurringGift.end = null
    this.checkOutRecurringGift.recurrencePattern = updatedRecurrencePattern
    this.checkOutRecurringGift.start = startDate(updatedRecurrencePattern).toDate()
  },

  setUserPaysFeesMobileGiving(userPaysFeesGiverSetting) {
    this.checkOutData.userPaysFees = userPaysFeesGiverSetting
  },

  /**
   * Sets state checkout data's state value savedGiftAsScheduledGift
   * @param {boolean} savedGiftAsScheduledGift
   * @returns {void}
   */
  setSavedGiftAsScheduledGift(savedGiftAsScheduledGift) {
    this.checkOutData.savedGiftAsScheduledGift = savedGiftAsScheduledGift
  },

  resetBillingInformation() {
    this.givingFlowStateData.billingInformation = {
      giveAnonymousGift: false,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: ''
    }
  },

  resetGivingFlowStateData() {
    this.checkOutData.giftNote = ''
    this.givingFlowStateData = {
      billingInformation: {
        giveAnonymousGift: false,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        emailAddress: ''
      },
      guestChoseSignInCta: false,
      giverMustLogin: false,
      panelCompletionProgressData: {
        billingInfoComplete: false,
        frequencyInfoComplete: false
      }
    }
  }
}
