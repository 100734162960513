import Proxy from './Proxy'

export default class TransactionsProxy extends Proxy {
  /**
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${import.meta.env.VITE_GIVING_API_BASE_URL}/v1`, parameters)
  }

  /**
   * * Get all deposits for the org
   *
   */
  async getAllDepositsByOrg() {
    return this.submit('get', `${this.endpoint}/deposits/getAllDepositsByOrg/`)
  }

  /**
   * * Get all deposits by webId. Used for RF
   *
   */
  async getDepositsByWebId(webId) {
    return this.submit('get', `${this.endpoint}/deposits/getDepositsByWebId/${webId}`)
  }

  /**
   * * Get deposits by batch key for credit transactions for the org
   * @param {string} batchNumber
   * @returns {Promise}
   *
   * */
  async getDepositDetailsByBatchNumber(batchNumber) {
    return this.submit('get', `${this.endpoint}/deposits/getDepositDetailsByBatchNumber/${batchNumber}`)
  }

  /**
   * * Get deposits by date for check transactions for the org
   *
   * @param {date} date
   * @returns {Promise}
   *
   * */
  async getDepositDetailsByBatchDate(date) {
    return this.submit('get', `${this.endpoint}/deposits/getDepositDetailsByBatchDate/${date}`)
  }

  /**
   * * Get all transactions
   *
   * @returns {Promise}
   */
  async getTransactions() {
    return this.submit('get', `${this.endpoint}/transactions`)
  }

  /**
   * * Get all transactions
   *
   * @returns {Promise}
   */
  async getGridTransactions() {
    return this.submit('get', `${this.endpoint}/transactions/grid`)
  }

  /**
   * * Get transaction insight data for a merchant
   *
   * @returns {Promise}
   */
  async getInsightsTotalGifts() {
    return this.submit('get', `${this.endpoint}/transactions/insights/totalgifts`)
  }

  /**
   * * Get all transactions for Individual
   * @returns {Promise}
   */
  async getIndTransactions() {
    return this.submit('get', `${this.endpoint}/transactions`)
  }

  async getIndGridTransactions({ ind_id }) {
    return this.submit('get', `${this.endpoint}/transactions/grid/${ind_id}`)
  }

  async getFilteredTransactions(payload, ind_id) {
    return this.submit('post', `${this.endpoint}/transactions/query/${ind_id}`, payload)
  }

  async postRegularTransaction(payload) {
    return this.submit('get', `${this.endpoint}/transactions`, payload)
  }

  // RECURRING
  /**
   * * Get all recurring transactions for the org
   *
   * @param id
   * @returns {Promise}
   */
  async getOrgRecurringTransactions({ webId }) {
    console.log('[TransactionsProxy] getOrgRecurringTransactions() webId: ', webId)
    return this.submit('get', `${this.endpoint}/scheduled/merchant/${webId}`)
  }

  /**
   * * Get all recurring transactions for the org
   *
   * @param giverId
   * @returns {Promise}
   */
  async getMemberRecurringTransactions({ giverId }) {
    console.log('[TransactionsProxy] getGiverGiftSchedules() giverId: ', giverId)
    return this.submit('get', `${this.endpoint}/scheduled/giver/${giverId}`)
  }

  /**
   * * Get a count of recurring transactions for a specific account
   * @param webId
   * @param accountId
   * @returns {Promise<unknown>}
   */
  async getScheduledGiftsByAccountIdCount(webId, accountId) {
    return this.submit('get', `${this.endpoint}/scheduled/count/${webId}/${accountId}`)
  }

  /**
   * *Create a gift
   *
   * @param payload
   * @returns {Promise}
   */
  async createRecurringGift(payload) {
    console.log('[TransactionsProxy] patchGiftSchedules() id: ', payload)
    return this.submit('post', `${this.endpoint}/scheduled/`, payload)
  }

  /**
   * *Update a gift
   *
   * @param payload
   * @returns {Promise}
   */
  async updateRecurringGift(payload) {
    console.log('[TransactionsProxy] patchGiftSchedules() id: ', payload)
    return this.submit('patch', `${this.endpoint}/scheduled/${payload.id}`, payload)
  }

  async updateSelectedTransactionDetails(payload, contributionId) {
    return this.submit('patch', `${this.endpoint}/transactions/update/transaction/${contributionId}`, payload)
  }

  /**
   * SPA -> Giving-API -> Payments-API Routes
   *
   */

  /**
   * Create a token
   * @param {*} payload
   * @returns
   */
  async createBankAccountToken(payload) {
    return this.submit('post', `${this.endpoint}/payments/giverbanktoken/`, payload)
  }

  /**
   * create a token while using mobile giving
   * @param {*} payload
   * @returns
   */
  async createMobileGivingBankAccountToken(payload) {
    return this.submit('post', `${this.endpoint}/payments/mobilegiving/giverbanktoken/`, payload)
  }

  /*
   * Create a token
   * @param {*} payload
   * @returns
   */

  async createCreditCardToken(payload) {
    return this.submit('post', `${this.endpoint}/payments/givertoken/`, payload)
  }

  /*
   * Create a token via mobile giving
   * @param {*} payload
   * @returns
   */

  async createMobileGivingCreditCardToken(payload) {
    return this.submit('post', `${this.endpoint}/payments/mobilegiving/givertoken/`, payload)
  }

  /**
   * @description Proxy to process a transaction
   * @param {object} payload
   * @returns {Promise}
   */
  async processGivingTransaction(payload) {
    return this.submit('post', `${this.endpoint}/payments/tokensale`, payload)
  }

  /**
   * Get data for a single transaction
   * @param {*} payload
   * @returns
   */
  async fetchTransactionDetails(payload) {
    return this.submit('post', `${this.endpoint}/payments/transaction/referenceId/`, payload)
  }

  /**
   * Void a transaction
   * @param {*} payload
   */
  async voidTransaction(payload) {
    return this.submit('post', `${this.endpoint}/payments/voidsale/`, payload)
  }

  /**
   * Refund a transaction
   * @param {*} payload
   */
  async refundTransaction(payload) {
    return this.submit('post', `${this.endpoint}/payments/refund/`, payload)
  }
}
