import useRruleHelpers from '@/helpers/rrule'

export default () => {
  const { newRecurrencePattern, startDate } = useRruleHelpers()
  const pattern = newRecurrencePattern()

  return {
    checkOutData: {
      accounts: [],
      feeAmount: 0,
      giftNote: '',
      paymentInfo: null,
      saveAsDefaultPaymentInfo: false,
      savedGiftAsScheduledGift: false,
      userPaysFees: false
    },
    checkOutRecurringGift: {
      recurrencePattern: pattern,
      active: false,
      start: startDate(pattern).toDate(),
      // NOT USED
      end: null
    },
    defaultPattern: pattern,
    receiptData: {},
    givingFlowStateData: {
      billingInformation: {
        giveAnonymousGift: false,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        emailAddress: ''
      },
      guestChoseSignInCta: false,
      giverMustLogin: false,
      panelCompletionProgressData: {
        billingInfoComplete: false,
        frequencyInfoComplete: false
      },
      forgotPasswordActive: false
    }
  }
}
