const giftFrequencyTypes = {
  ONETIME: 'one-time',
  SCHEDULED: 'scheduled',
  ALL: 'all'
}

const giftPaymentTypes = {
  ONLINE: 'online',
  CREDIT_CARD: 'credit',
  ACH: 'ach',
  DIRECT_DEPOSIT: 'direct',
  CASH: 'cash',
  OTHER: 'other',
  ALL: 'all'
}

const giftRangeSelections = {
  LAST_MONTH: 'last-month',
  CURRENT_MONTH: 'current-month',
  LAST_YEAR: 'last-year',
  CURRENT_YEAR: 'current-year'
}

export default {
  giftFrequencyTypes,
  giftPaymentTypes,
  giftRangeSelections
}
