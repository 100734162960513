/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import axios from 'axios'
import router from '@/router/index'
import { LOGIN } from '@/constants/apiConstants.js'
import stores from '@/stores'
import sentryErrorLoggingHelper from '@/helpers/sentryErrorLoggingHelper'

axios.defaults.headers.common.Accept = 'application/json'

const Error400ResponseUrlExclusions = [
  `${import.meta.env.VITE_GIVING_API_BASE_URL}/v1/payments/tokensale`,
  `${import.meta.env.VITE_GIVING_API_BASE_URL}/v1/profile/supermegachurch/mobilegiving/profile`
]

/**
 * Checks if the error response indicates an invalid token or unauthorized access.
 * @param {Object} error - The error object from an Axios response.
 * @returns {boolean} - Returns true if the error indicates an invalid token or unauthorized access, otherwise false.
 */
const expectedLoginOrInvalidTokenResponse = (error) => {
  return (
    error?.response?.data?.message === 'Invalid Token' ||
    error?.response?.status === 401 ||
    (error?.response?.status === 403 && !router.currentRoute.value.fullPath.includes('login'))
  )
}

/**
 * Checks if the current request is a giving flow giver login request.
 * @returns {boolean} - Returns true if the current request is a giving flow giver login request, otherwise false.
 */
const isGivingFlowGiverLoginRequest = () => {
  const { givingFlowStateData } = stores.useCheckOutStore()
  return givingFlowStateData.giverMustLogin || givingFlowStateData.guestChoseSignInCta
}

/**
 * Logs out the user and redirects to the appropriate login route.
 * @returns {Promise<void>} - A promise that resolves when the user is logged out and redirected.
 */
const logoutUser = async () => {
  const loginRoute = {
    name: router.currentRoute.value.fullPath.includes('staff') ? 'login.index' : 'member.login'
  }
  await stores.useSessionStore().logout()
  await router.replace(loginRoute) // suppress duplicate route errors
}

/**
 * Checks if the error response indicates a wrong password during password reset.
 * @param {Object} error - The error object from an Axios response.
 * @returns {boolean} - Returns true if the error indicates a wrong password during password reset, otherwise false.
 */
const expectedResetPasswordWrongPasswordResponse = (error) => {
  return error?.response?.status === 403 && error?.request?.responseURL.indexOf('/v1/forgot/check_member') !== -1
}

// set up an axios response interceptor so that if there are invalid tokens or api exceptions, we can redirect to the appropriate page or display an appropriate error
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const isExpectedResetPasswordWrongPasswordResponse = expectedResetPasswordWrongPasswordResponse(error)
    const shouldLogoutUser = expectedLoginOrInvalidTokenResponse(error) && !isGivingFlowGiverLoginRequest()

    if (isExpectedResetPasswordWrongPasswordResponse) {
      // user is trying to change password, but the old password is wrong
      return Promise.resolve(error)
    } else if (shouldLogoutUser) {
      logoutUser()
    } else if (error?.response?.status === 403 && router.currentRoute.value.fullPath.includes('login')) {
      // invalid login, don't log
      if (error?.response?.data?.message === LOGIN.EMAIL_NOT_VERIFIED) {
        return Promise.resolve(error)
      }
      return Promise.reject(error)
    } else {
      if (
        error?.response?.status !== 403 &&
        error?.response?.status !== 404 &&
        !Error400ResponseUrlExclusions.includes(error.request.responseURL)
      ) {
        console.log(error.message || error?.response?.data?.message)
        sentryErrorLoggingHelper().sentryErrorWithContext(error)
      }
    }

    return Promise.reject(error)
  }
)

// Interceptor adds API key and auth token (where applicable) to header
axios.interceptors.request.use((config) => {
  const authToken = localStorage.getItem('sko_token')
  const smsToken = stores.useSessionStore().getSmsToken
  const smsPhone = stores.useSessionStore().getSmsPhone
  const isSkApi =
    config.url.indexOf(import.meta.env.VITE_GIVING_API_BASE_URL) !== -1 ||
    config.url.indexOf(import.meta.env.VITE_ACCOUNT_API_BASE_URL) !== -1

  if (isSkApi) {
    const headers = {
      ...config.headers,
      'x-api-key': import.meta.env.VITE_API_KEY,
      Authorization: authToken ? `Bearer ${authToken}` : undefined
    }

    if (smsToken) {
      headers['x-sms-phone'] = smsPhone
      headers['x-sms-token'] = smsToken
    } else {
      headers['x-sms-phone'] = undefined
      headers['x-sms-token'] = undefined
    }

    return {
      ...config,
      headers
    }
  } else {
    return config
  }
})

// exported strictly for corresponding unit test file
export {
  expectedLoginOrInvalidTokenResponse,
  expectedResetPasswordWrongPasswordResponse,
  isGivingFlowGiverLoginRequest,
  logoutUser
}
