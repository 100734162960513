import { givingFlowConstants } from '@/constants/index.js'

const { FREQUENCY_CHOICES } = givingFlowConstants

export default () => ({
  accounts: [],
  activeForm: {},
  amountChips: [],
  amountChipValues: ['10', '25', '50', '100', '250', '500', 'Other'],
  bodyFontColor: '#000',
  bodyText: '',
  buttonColor: '#fff',
  buttonTextColor: '#000',
  cardBackground: '#ccc',
  forms: [],
  giftAccounts: {},
  header: '',
  headerColor: '#000',
  image: '',
  subheaderColor: '#666',
  subtitle: '',
  textToGiveGiftAccounts: [],
  defaultCardConfig: {
    active: true,
    formData: {
      text: '',
      chips: ['10', '25', '100', 'Other'],
      header: 'Give Today',
      accounts: [],
      subtitle: '',
      customizationOptions: {
        img: null,
        bodyFontColor: '#000',
        buttonBgColor: '#3f3f3f',
        cardBackground: '#fff',
        buttonTextColor: '#fff',
        headerFontColor: '#000',
        subheaderFontColor: '#000'
      }
    }
  },
  givingCardGiverOptions: {
    frequencyChoice: FREQUENCY_CHOICES.ONCE,
    givingFlowOptionSelected: false
  }
})
