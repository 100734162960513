export default {
  getForms(state) {
    return state.forms
  },
  formLoaded(state) {
    return Object.keys(state.activeForm).length
  },
  getCurrentlyActiveForm(state) {
    return state.activeForm
  },
  getDefaultCardConfig(state) {
    return state.defaultCardConfig
  }
}
