export default {
  getCheckOutData(state) {
    return state.checkOutData
  },

  getCheckOutRecurringGift(state) {
    return state.checkOutRecurringGift
  },

  getBillingInfoEmailAddress(state) {
    return state.givingFlowStateData.billingInformation.emailAddress
  }
}
