export default {
  getOrgName(state) {
    return state.orgName
  },

  getOrgData(state) {
    return state.orgData
  },

  getWebId(state) {
    return state.webId
  },

  getMerchantData(state) {
    return state.merchantData
  }
}
