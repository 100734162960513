/* eslint-disable no-unused-vars */
export default {
  // Access Contribution Manager and Online Giving as a Staff Member
  SEC_ACCESS: { slot: '2', mask: 0x00000004 },

  // contribution listing access
  SEC_LISTING: { slot: '6', mask: 0x00000001 },

  // admin access
  ADMIN_ACCESS: { slot: '3', mask: 0x00000001 }, // login in admin (desktop)
  ADMIN_MANAGE_USER_ACCESS: { slot: '3', mask: 0x00000002 }, // manager users
  ADMIN_MANAGE_USER_ADD: { slot: '3', mask: 0x00000004 }, // add users
  ADMIN_MANAGE_USER_UPDATE: { slot: '3', mask: 0x00000008 }, // update users
  ADMIN_MANAGE_USER_DELETE: { slot: '3', mask: 0x00000010 } // delete users
}

export const SEC_ACCESS = 'SEC_ACCESS'
export const SEC_LISTING = 'SEC_LISTING'
export const ADMIN_ACCESS = 'ADMIN_ACCESS'
export const ADMIN_MANAGE_USER_ACCESS = 'ADMIN_MANAGE_USER_ACCESS'
export const ADMIN_MANAGE_USER_ADD = 'ADMIN_MANAGE_USER_ADD'
export const ADMIN_MANAGE_USER_UPDATE = 'ADMIN_MANAGE_USER_UPDATE'
export const ADMIN_MANAGE_USER_DELETE = 'ADMIN_MANAGE_USER_DELETE'

export const DEFAULT_USER = {
  access1: '469630911',
  access2: '4168639', // set from the payload
  access3: '7811647',
  access4: '48234495',
  access5: '33554367',
  access6: '4194303',
  access7: '0',
  access8: '0',
  access9: '0',
  access10: '31',
  active: true,
  email: '',
  first_name: '',
  last_name: '',
  loc_id: '000',
  online_giving_access: true,
  shouldEmailUser: true
}
